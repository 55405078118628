.title {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 12px;
	color: var(--black-100);
}

.buttons {
	position: sticky;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	padding: 10px 0 24px 0;
	background: #fff;
	z-index: 1001;
}

.resetButton {
	border: 1px solid var(--error-100) !important;
}

.resetButton .buttonText {
	color: var(--error-100);
}

.buttonText {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.dropdownContainer {
	min-width: 300px;
}
