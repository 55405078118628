.headerTab {
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 4px 6px;
	background: none;
	border: 1px solid transparent;
	transition: background 0.2s, border 0.2s;
	cursor: pointer;
}

.headerTab:not(.headerTabSelected):hover {
	border: 1px solid #cacaca;
}

.headerTab:hover .count {
	color: #fff;
	background: var(--black-80);
}

.headerTabSelected {
	background: var(--primary-05);
}

.headerTabSelected .title {
	font-weight: 600;
	color: var(--primary-100);
}

.headerTabSelected .count,
.headerTabSelected:hover .count {
	color: #fff;
	background: var(--primary-100);
}

.title,
.count {
	transition: color 0.2s, background 0.2s;
}

.title {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	margin-bottom: 0;
	color: var(--black-80);
}

.count {
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	padding: 2px 8px;
	color: #414141;
	background: var(--bg-30);
}