.deliverySelectContainer {
	background: var(--bg-20);
	border-radius: 0px;
	height: 64px;
	border: 1px solid var(--bg-20);
	padding: 6px 3px;
    padding-bottom: 0;
	position: relative;
}

.deliverySelectContainer:focus-within {
	border: 1px solid var(--primary-100) !important;
}

.selectsContainer {
	display: flex;
	align-items: center;
}

.title {
	top: 14px;
	left: 16px;
    font-size: 12px;
    font-weight: 500;
    color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	padding-left: 12px;
}

.deliveryAreaSuggestionContainer {
    font-size: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.deliveryAreaSuggestions {
    display: flex;
    gap: 4px;
    font-weight: bold;
    align-items: center;
}

.deliveryAreaSuggestion {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 13px;
}

.deliveryAreaOption {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.deliveryAreaOptionValue {
    align-items: center;
    display: flex;
    gap: 4px;
}

.inputContainer {
    height: 39.15px;
    width: 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    overflow-x: auto;
    overflow-y: hidden;
}

.inputContainer::-webkit-scrollbar {
    height: 3px;
}

.inputContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.inputContainer::-webkit-scrollbar-thumb {
    background: #888;
}

.inputContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    font: inherit;
    outline: none;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 100px;
}

.input:disabled {
    cursor: not-allowed;
}

.suggestions {
    max-height: 240px;
    overflow-y: auto;
    max-width: 537.5px;
}

.clearIcon {
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    right: 0;
    display: none;
}

.deliverySelectContainer:hover .clearIcon {
    display: block;
}