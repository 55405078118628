.barSpinner {
	height: 100%;
	display: grid;
	grid-auto-flow: column;
	gap: 4px;
	rotate: 180deg;
}

.bar {
	width: 2px;
	background: var(--black-100);
	border-radius: 7px;
	animation: wave 1.7s ease-in-out infinite;
}

.bar:nth-child(2) {
	animation-delay: 0.2s;
}

.bar:nth-child(3) {
	animation-delay: 0.4s;
}

.bar:nth-child(4) {
	animation-delay: 0.6s;
}

.bar:nth-child(5) {
	animation-delay: 0.8s;
}

.bar:nth-child(6) {
	animation-delay: 1s;
}

@keyframes wave {
	0% {
		height: 26%;
	}

	50% {
		height: 100%;
	}

	100% {
		height: 26%;
	}
}
