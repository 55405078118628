.id {
	color: #278ea5;
	background: #eff2f6;
	border-radius: 8px;
	padding: 6px 16px;
	font-size: 15px;
	display: inline-block;
}

.tableColumn {
	min-width: 120px;
}