.link {
	max-width: 56px;
	display: grid;
	grid-template-columns: repeat(2, max-content);
	align-items: center;
	gap: 12px;
	padding: 12px;
	border-radius: 50%;
	overflow-x: hidden;
	transition: background 0.3s, max-width 0.3s, border-radius 0.3s;
}

.link:hover {
	background: var(--primary-05);
}

.link:hover .icon {
	fill: var(--primary-100);
}

.link:hover .title {
	font-weight: 600;
	color: var(--primary-100);
}

.linkExpanded {
	max-width: 100%;
	border-radius: 0;
}

.linkActive,
.linkActive:hover {
	background: var(--primary-100);
}

.linkActive .icon,
.linkActive:hover .icon {
	fill: #fff;
	width: 24px;
	height: 24px;
}

.linkActive .title,
.linkActive:hover .title {
	font-size: 14px;
	line-height: 18px;
	font-weight: 600;
	color: #fff;
}

.icon {
	width: 20px;
	height: 20px;
	fill: #b5b5b5;
	transition: fill 0.2s, width 0.2s, height 0.2s;
}

.iconContainer {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	font-size: 14px;
	line-height: 16px;
	color: #999999;
	transition: color 0.2s;
}