.subTab {
	font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
}

.subTabActive,
.subTab:hover {
	color: black !important;
	background: #e3f3f6 !important;
	border-color: transparent !important;
}

.count {
	font-size: 12px;
	padding: 0 7px;
	margin-left: 8px;
	color: #00171d;
	background: #fafafa;
	border-radius: 0px;
	transition: color 0.3s, background 0.3s;
}

.countActive {
	color: var(--primary-100);
}
