.root {
	position: relative;
}

.expandableSidebarLink {
	max-width: 56px;
	max-height: 56px;
	border: 1px solid transparent;
	border-radius: 50%;
	overflow: hidden;
	transition: max-height 0.3s linear, max-width 0.3s, border 0.3s,
		border-radius 0.3s;
}

.sidebarExpanded {
	max-width: 100%;
	border-radius: 0;
}

.linkExpanded {
	max-height: 100%;
	border: 1px solid var(--bg-50);
}

.linkExpanded:hover .linkHeader,
.linkExpanded .linkHeader {
	background: var(--bg-20);
}

.linkExpanded .chevron {
	fill: var(--primary-100);
}

.linkHeader {
	width: 100%;
	max-height: 54px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	padding: 12px 12px;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	transition: background 0.3s;
}

.linkHeader:hover {
	background: var(--primary-05);
}

.linkHeader:hover .icon {
	fill: var(--primary-100);
}

.linkHeader:hover .title {
	font-weight: 600;
	color: var(--primary-100);
}

.linkHeader:hover .chevron {
	fill: var(--primary-100);
}

.linkHeaderActive,
.linkHeaderActive:hover {
	background: var(--primary-100);
}

.linkHeaderActive .icon,
.linkHeaderActive:hover .icon {
	fill: #fff;
	width: 32px;
	height: 32px;
}

.linkHeaderActive .title,
.linkHeaderActive:hover .title {
	font-weight: 600;
	color: #fff;
}

.linkHeaderActive .chevron,
.linkHeaderActive:hover .chevron {
	fill: #fff;
}

.linkHeaderExpanded:hover .icon,
.linkHeaderExpanded .icon {
	fill: var(--primary-100);
}

.linkHeaderExpanded:hover .title,
.linkHeaderExpanded .title {
	font-weight: 600;
	color: var(--primary-100);
}

.linkHeaderExpanded .chevron,
.linkHeaderExpanded:hover .chevron {
	fill: var(--primary-100);
}

.titleContainer {
	display: flex;
	align-items: center;
	gap: 12px;
}

.icon {
	width: 24px;
	height: 24px;
	fill: #b5b5b5;
	transition: fill 0.2s, width 0.2s, height 0.2s;
}

.iconContainer {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.title,
.linkTitle {
	font-size: 14px;
	line-height: 16px;
	color: #999999;
	transition: color 0.2s;
}

.chevron {
	width: 18px;
	height: 18px;
	fill: #b5b5b5;
	rotate: 180deg;
	transition: rotate 0.3s ease, fill 0.2s;
}

.chevronInverted {
	rotate: 0deg;
}

.links {
	display: grid;
}

.link {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, max-content);
	align-items: center;
	gap: 12px;
	padding: 16px 12px;
	transition: background 0.3s;
}

.link:hover {
	background: var(--primary-05);
}

.link:hover .dot {
	background: var(--primary-100);
}

.link:hover .linkTitle {
	font-weight: 600;
	color: var(--primary-100);
}

.linkActive,
.linkActive:hover {
	background: var(--primary-100);
}

.linkActive .dot,
.linkActive:hover .dot {
	background: #fff;
}

.linkActive .linkTitle,
.linkActive:hover .linkTitle {
	font-weight: 600;
	color: #fff;
}

.dot {
	width: 6px;
	height: 6px;
	background: #b5b5b5;
	border-radius: 50%;
	transition: background 0.3s;
}

.preview {
	position: fixed;
	left: 96px;
	padding: 16px;
	background: #fff;
	box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.16);
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s, visibility 0.2s;
	z-index: 1104;
}

.previewVisible {
	visibility: visible;
	opacity: 1;
}
