.customEmpty {
	text-align: center;
	max-width: 350px;
	padding: 40px;
	margin: 48px auto 0 auto;
	border: 1px solid var(--bg-30);
}

.iconContainer {
	width: 96px;
	height: 96px;
	display: inline-grid;
	place-items: center;
	margin-bottom: 16px !important;
	background: var(--bg-10);
	border-radius: 50%;
}

.title {
	font-size: 24px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 8px !important;
	color: var(--black-80);
}

.description {
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 0;
	color: #656565;
}

.button {
	margin-top: 24px;
}
