.page {
	width: 210mm;
	min-height: 278mm;
	padding: 16mm;
	margin: auto;
	display: flex;
	flex-direction: column;
	-webkit-print-color-adjust: exact;
	line-height: 1.0;
	page-break-before: always;
}

.valueContainer {
	display: flex;
	font-size: 13px;
}

.valueTitle {
	font-weight: normal;	
	margin-right: 6px;
	font-size: 13px;
}

.value {
	font-weight: bold;
	font-size: 13px;
	text-align: center;
}

.columnValueContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	justify-items: center;
	font-size: 13px;
	max-width: 200px;
	text-align: center;
}

.smallValue {
	font-size: 12px;
	font-weight: normal;
	text-align: center;
	padding: 4px 0;
	word-wrap: break-word;
}

.table {
	border: 1px solid black;
	margin-top: 12px;
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.divider {
	width: 100%;
	height: 1px;
	background: black;
	margin: 32px 0;
}