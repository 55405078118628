.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
}

.requestProgress {
	margin-top: 30px;
	width: 100%;
	height: 4px;
	position: relative;
	overflow: hidden;
	background: linear-gradient(to right, #278ea5, #09796b)
}
  
.bar {
	position:absolute;
	width: calc(100% * 2/7);
	height: 100%;
	display: flex;
	animation: move 2s linear infinite;
	background-color: #d9d9d9;
}

@keyframes move {
	0%   {transform: translate(-100%, 0)}
	100% {transform: translate(calc(7/2*100%), 0)}
}

.logo {
	width: 175px;
	filter: brightness(0) saturate(100%) invert(0%) sepia(38%) saturate(25%)
		hue-rotate(71deg) brightness(107%) contrast(105%);
}

.customLogo {
	width: 180px;
	object-fit: cover;
}
