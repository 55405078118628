.container {
	display: flex;
	flex-direction: column;
}

.vehicleTypesContainer {
	background: #ffffff;
	margin-bottom: 24px;
	padding: 24px;
	border: 1px solid #ebebeb;
}

.vehicleTypes {
	display: flex;
	flex-wrap: wrap;
}

.vehicleType {
	background: #ffffff;
	border: 1px solid #f0f0f0;
	border-radius: 100px;
	white-space: nowrap;
	margin-right: 12px;
	margin-top: 16px;
	padding: 16px 24px;
}

.vehicleTypeSelected {
	background: #288ea5;
	border: 1px solid #f0f0f0;
	border-radius: 100px;
	white-space: nowrap;
	margin-right: 12px;
	margin-top: 16px;
	padding: 16px 24px;
}

.title {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	color: black;
}

.actions {
	display: flex;
	justify-content: flex-end;
	padding: 14px 0px;
	position: sticky;
	bottom: 0;
	background: white;
	z-index: 99;
}

.footer {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}
