.datePicker {
	position: relative;
}

.datePicker:focus-within .title {
	color: var(--primary-100);
}

.title {
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	position: absolute;
	top: 10px;
	left: 16px;
	color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	z-index: 1;
	user-select: none;
	pointer-events: none;
}

.calendarIcon {
	width: 24px;
	height: 24px;
}

.titleError {
	color: var(--error-100) !important;
}

.pickerError {
	background: var(--error-10) !important;
	border: 1px solid var(--error-100) !important;
}
