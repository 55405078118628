.container {
	display: flex;
	position: relative;
}

.preview {
	width: 100%;
	height: calc(100vh - 64px);
	outline: none;
	border: none;
	background: rgb(234, 234, 234);
	padding: 0;
}

.editorPanel {
	height: calc(100vh - 64px);
	width: 470px;
	flex: 1;
	position: fixed;
	left: 0;
	margin-left: 72px;
	top: 64px;
	background: white;
	box-shadow: -6px 0 16px -8px rgb(0 0 0 / 5%), -9px 0 28px 0 rgb(0 0 0 / 3%),
		-12px 0 48px 16px rgb(0 0 0 / 1%);
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 16px;
	z-index: 10;
	overflow-y: auto;
	padding-bottom: 156px;
}

.editInvoiceHeader {
	padding-top: 24px;
	padding-bottom: 24px;
	margin-bottom: 24px;
	position: sticky;
	top: 0;
	background: white;
	z-index: 25;
}

.pageContainer {
	display: flex;
	flex: 2;
	align-items: center;
	justify-content: center;
	justify-items: center;
	background-color: lightgray;
	min-height: calc(100vh - 64px);
}

.page {
	width: 210mm;
	height: 278mm;
	padding: 12mm;
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	line-height: 1;
	page-break-before: always;
	background: white;
	overflow: hidden;
	position: relative;
	color: black;
}

.pageInvisible {
	width: 210mm;
	height: 278mm;
	padding: 12mm;
	display: flex;
	flex-direction: column;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	line-height: 1;
	page-break-before: always;
	background: white;
	display: none;
	overflow: hidden;
	position: relative;
	color: black;
}

.posPage {
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	line-height: 1;
	page-break-before: always;
	background: white;
	overflow: hidden;
	position: relative;
	color: black;
}

.posPageInvisible {
	display: flex;
	flex-direction: column;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	line-height: 1;
	page-break-before: always;
	background: white;
	display: none;
	overflow: hidden;
	position: relative;
	color: black;
}

.topHeader {
	font-weight: bolder;
	font-size: 28px;
	margin-bottom: 2px;
}

.valueContainer {
	display: flex;
	font-size: 13px;
}

.valueTitle {
	font-weight: normal;
	margin-right: 6px;
	font-size: 13px;
}

.value {
	font-weight: bold;
	font-size: 13px;
	text-align: center;
}

.columnValueContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	justify-items: center;
	font-size: 13px;
	max-width: 200px;
	text-align: center;
}

.smallValue {
	font-size: 12px;
	font-weight: normal;
	text-align: center;
	padding: 4px 0;
	word-wrap: break-word;
}

.table {
	border: 1px solid black;
	margin-top: 12px;
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.divider {
	width: 100%;
	height: 1px;
	background: black;
	margin: 32px 0;
}

.logo {
	max-width: 120px;
	height: 80px;
	object-fit: contain;
}

.logoSmall {
	max-width: 64px;
	height: 48px;
	object-fit: contain;
}

.header {
	display: flex;
	width: 100%;
}

.footer {
	display: flex;
	flex-direction: column;
	margin-top: auto;
	padding-top: 16px;
	border-top: 1px dashed black;
	overflow-y: hidden;
}

.topFooter {
	border: none;
}

.headerContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	width: 100%;
}

.infoContainer {
	margin-left: auto;
	display: flex;
	flex-direction: column;
}

.billHeading {
	font-size: 14px;
	padding: 8px;
	color: white;
	background: black;
	align-self: flex-start;
}

.recipientContainer {
	display: flex;
	flex-direction: column;
}

.additionalInfo {
	padding: 24px;
	flex: 1;
	white-space: pre-line;
	line-height: 1.6;
}

.additionalInfo1 {
	padding: 12px;
	flex: 1;
	white-space: pre-line;
	line-height: 1.6;
	max-width: 200px;
	word-wrap: break-word;
}

.footerInfo {
	display: flex;
	flex-direction: column;
}

.buttonContainer {
	position: fixed;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 470px;
	padding-top: 16px;
	padding-bottom: 32px;
	background: white;
	z-index: 10;
	margin-left: 88px;
}

.itemName {
	font-size: 10px;
	max-width: 300px;
	font-weight: bold;
}

.posItemName {
	font-size: 10px;
	font-weight: bold;
	color: black;
}

.dueAmount {
	display: flex;
	margin-top: 8px;
	background: black;
	padding: 8px 24px;
	align-items: center;
	white-space: nowrap;
	font-size: 10px;
}

.contactRow {
	display: flex;
	margin-top: 6px;
	align-items: center;
	font-size: 10px;
}

.contactText {
	max-width: 196px;
	margin-left: 8px;
	font-size: 10px;
}

.scissorIcon {
	width: 14px;
	height: 14px;
	position: absolute;
	right: calc(16mm - 20px);
	margin-top: -24px;
}

.noteTitle {
	color: #ff5959;
	font-weight: bold;
	font-size: 10px;
	margin-right: 6px;
}

.noteText {
	font-size: 10px;
}

.infoItemContainer {
	display: flex;
	background: #f7f7f7;
	border: 1px solid #ebebeb;
	padding: 6px 19px;
	margin-top: 8px;
}

.infoItem {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 2px solid #ebebeb;
	font-size: 10px;
}

.infoItem:last-of-type {
	border: none;
}

.infoItemHeading {
	font-weight: bold;
	font-size: 10px;
	color: black;
	margin-right: 6px;
}

.infoItemContent {
	font-size: 10px;
}

.pageNumber {
	position: absolute;
	font-size: 10px;
	color: black;
	font-weight: bold;
	right: 48px;
	bottom: 24px;
}

.formContainer {
	border: 1px solid #ebebeb;
	border-radius: 8px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.infoItemContainerAlt {
	display: flex;
}

.infoItemAlt {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f7f7f7;
	font-size: 10px;
	padding: 6px 19px;
	border: 1px solid #ebebeb;
}

.attachmentImageContainer {
	width: 120px;
	height: 120px;
	margin-left: auto;
	background: white;
}

.attachmentImageContainer img {
	width: 120px;
	height: 120px;
	object-fit: contain;
}

@media print {
	.page {
		display: flex;
	}

	.pageInvisible {
		display: flex;
	}

	.posPage {
		display: flex;
	}

	.posPageInvisible {
		display: flex;
	}
}
