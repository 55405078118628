.container {
	padding: 24px 0;
	margin: 0 auto;
}

.container p,
.container h1,
.container span {
	margin: 0;
}

.titleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}

.pageTitle {
	font-size: 24px;
	font-weight: 600;
	line-height: 34px;
	letter-spacing: 0.75px;
	color: #000000;
}

.topButtons {
	display: flex;
	align-items: center;
	gap: 16px;
}

.tabs {
	display: flex;
	gap: 10px;
	margin-bottom: 12px;
}

.invoiceColumn {
	padding: 16px 16px 16px 35px !important;
}

.filterButtonContainer {
	width: 184px;
	margin-bottom: 20px;
	margin-left: auto;
}

.filterColumnsButton {
	display: flex !important;
	align-items: center;
	gap: 10px;
	padding: 10px 20px !important;
	color: #4b4b4b !important;
}

.filterOptions {
	display: grid;
	gap: 12px;
}

.subTabs {
	position: relative;
	padding: 12px 14px;
	margin-bottom: 12px;
	border: 1px solid var(--bg-50);
}

.subTabsContainer {
	max-height: 96px;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	overflow-y: hidden;
}

.subTabsContainerExpanded {
	max-height: 100%;
}

.subTabsToggleButton {
	width: 40px;
	height: 40px;
	position: absolute;
	bottom: 0;
	left: 50%;
	padding: 0;
	background: #fff;
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
	border: none;
	border-radius: 50%;
	translate: -50% 50%;
	display: none;
	cursor: pointer;
}

.subTabsToggleButton img {
	width: 24px;
}

.subTabsToggleButtonVisible {
	display: initial;
}

.invoiceNo {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 16px;
	color: #242529;
}

.pickingModal {
	padding: 16px;
}

.modalTitleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}

.modalTitle,
.printTitle {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 0;
	color: #242529;
}

.printTitle {
	font-size: 22px;
	text-align: center;
	margin-bottom: 30px;
	display: none;
}

.closeIcon {
	width: 25px;
	height: 25px;
	stroke: #000;
	transition: stroke 0.2s;
	cursor: pointer;
}

.closeIcon:hover {
	stroke: #aaa;
}

.modalContent {
	position: relative;
}

.summaryRow {
	font-weight: 600;
	position: sticky;
	bottom: 0;
	background: #fff;
	z-index: 1;
}

.summaryRow :nth-child(3) {
	text-align: right;
}

.pickingTables > :last-child {
	display: none;
}

@media print {
	.pickingTableContainer {
		padding: 60px;
	}

	.modalContent {
		max-height: none;
	}

	.pickingTables > :first-child {
		display: none;
	}

	.pickingTables > :last-child {
		display: initial;
	}

	.printTitle {
		display: block;
	}
}

.downloadIcon {
	fill: #fff;
	width: 16px;
	height: 16px;
}

.countSelect {
	padding: 4px 16px;
	border: 1px solid #f5f5f5;
	background: #f9f9f9;
	border-radius: 48px;
	margin: 0 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	color: #292929;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	align-self: center;
	cursor: pointer;
}

.customerName {
	font-size: 12px;
	font-weight: bold;
}

.customerPhone {
	font-size: 12px;
	color: #000;
	white-space: nowrap;
}

.customerLocation {
	font-size: 11px;
}

.sourceTag {
	padding: 2px 12px;
	margin-top: 4px;
	align-self: flex-start;
	font-size: 11px;
}

.invoiceNoContainer {
	display: flex;
	align-items: center;
	margin-top: 4px;
}

.rowSelection,
.duplicateOrderIndicator {
	display: flex;
	align-items: center;
}

.rowSelection {
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	border: 1px solid var(--bg-30);
	border-bottom: none;
    gap: 12px;
}

.duplicateOrderIndicator {
	gap: 16px;
}

.duplicateOrderIndicator p {
	color: #000;
}

.yellowRectangle {
	width: 16px;
	height: 16px;
	background: #ffe4b3;
}

.rowSelectionText {
	color: var(--black-100);
}

.rowSelectionText span {
	color: var(--error-100);
	cursor: pointer;
}

.deliveryPartnerNameColumn,
.inTransitSubTabButtonContent {
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
}

.deliveryPartnerNameColumn {
	gap: 10px;
}

.inTransitSubTabButton {
	display: flex !important;
	align-items: center;
}

.inTransitSubTabButtonContent {
	gap: 12px;
}

.customerNameContainer {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.customerPhoneContainer {
	display: flex;
	align-items: center;
	gap: 2px;
}

.duplicateOrdersButton {
	height: auto !important;
	display: flex !important;
	align-items: center;
	gap: 10px;
	padding: 8px !important;
	border: 1px solid var(--bg-50) !important;
	border-radius: 0 !important;
}

.duplicateOrdersButton:hover,
.duplicateOrdersButtonActive {
	background: #e8f0f2 !important;
	border: 1px solid #e8f0f2 !important;
}

.duplicateOrderIndicatorText {
	font-weight: 500;
	line-height: 16px;
	color: #000000;
}

.duplicateOrderCount,
.duplicatePhoneCount {
	font-size: 12px;
	font-weight: 600;
	line-height: 12px;
	padding: 3px 6px;
	color: #fff;
}

.duplicateOrderCount {
	background: var(--primary-100);
}

.duplicatePhoneCount {
	color: #202020;
	background: #f7b44f;
}

.deliveryStatus {
	display: flex;
	align-items: center;
	margin-top: 8px;
	text-align: left;
}

.reason {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 30ch;
}

@media print {
	.pickingProductInfoIcon {
		display: none !important;
	}
}

.datesContainer {
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.dateBadge {
	font-size: 11px;
	display: flex;
	align-items: center;
	gap: 6px;
	align-self: flex-start;
	padding: 2px 8px;
	border-radius: 12px;
	white-space: nowrap;
}

.dateBadge span {
	font-weight: bold;
	color: black;
	font-size: 10px;
}

.highlighted {
	background: var(--primary-50);
	color: white;
}

.dropdown {
    padding: 16px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 0;
    position: absolute;
    z-index: 999;
    background: white;
    margin-top: 4px;
    opacity: 0;
    right: 0;
    transform: translateY(-12px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    visibility: hidden;
}

.dropdown.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.dropdownContainer {
    position: relative;
}

.tableButtons {
    display: flex;
    gap: 12px;
}

.divider {
    background: #EBEBEB;
    height: 1px;
    width: 100%;
}

.count {
	font-size: 12px;
	padding: 0 7px;
	margin-left: 8px;
	color: #00171d;
	background: var(--bg-30);
	border-radius: 0px;
	transition: color 0.3s, background 0.3s;
}

.countActive {
    font-size: 12px;
	padding: 0 7px;
	margin-left: 8px;
	border-radius: 0px;
	transition: color 0.3s, background 0.3s;
    color: #fff;
	background: var(--primary-100);
}

.selectedDeliveryPartner {
    background: #e3f3f6 !important;
}

.badge {
	color: #00171d;
	background: #e8f0f2;
	padding: 2px 6px;
	font-size: 10px;
    align-self: flex-start;
}

.imageContainer {
	width: 44px;
	height: 44px;
	border-radius: 4px;
	overflow: hidden;
}