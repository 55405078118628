html,
body {
	padding: 0;
	margin: 0;
}

:root {
	/* Primary colors */
	--primary-150: #144753;
	--primary-140: #185663;
	--primary-130: #1c6474;
	--primary-120: #207285;
	--primary-110: #248095;
	--primary-100: #288ea5;
	--primary-90: #3799af;
	--primary-80: #48a3b8;
	--primary-70: #5aaec1;
	--primary-60: #6eb9c9;
	--primary-50: #82c4d2;
	--primary-40: #99cfdb;
	--primary-30: #b0dbe4;
	--primary-20: #c9e7ed;
	--primary-10: #e3f3f6;
	--primary-05: #f2f8fa;

	/* Success colors */
	--success-150: #117361;
	--success-140: #158a74;
	--success-130: #18a188;
	--success-120: #1cb89b;
	--success-110: #1fcfae;
	--success-100: #22e6c2;
	--success-90: #37e8c8;
	--success-80: #4bebcd;
	--success-70: #60edd3;
	--success-60: #75f0d9;
	--success-50: #8bf2df;
	--success-40: #a2f5e6;
	--success-30: #b8f7ec;
	--success-20: #cffaf2;
	--success-10: #e7fcf9;
	--success-05: #f2fefb;

	/* Caution colors */
	--caution-150: #7a5927;
	--caution-140: #936b2f;
	--caution-130: #ab7d37;
	--caution-120: #c48f3f;
	--caution-110: #dca047;
	--caution-100: #f6b44f;
	--caution-90: #f6ba5f;
	--caution-80: #f7c171;
	--caution-70: #f8c982;
	--caution-60: #f9d093;
	--caution-50: #fad8a5;
	--caution-40: #fbe0b7;
	--caution-30: #fce7c9;
	--caution-20: #fdefdb;
	--caution-10: #fef7ed;
	--caution-05: #fffbf4;

	/* Error colors */
	--error-150: #802d2d;
	--error-140: #993636;
	--error-130: #b33e3e;
	--error-120: #cc4747;
	--error-110: #e65050;
	--error-100: #ff5959;
	--error-90: #ff6a6a;
	--error-80: #ff7a7a;
	--error-70: #ff8b8b;
	--error-60: #ff9c9c;
	--error-50: #ffacac;
	--error-40: #ffbdbd;
	--error-30: #ffcdcd;
	--error-20: #ffdede;
	--error-10: #ffeeee;
	--error-05: #fff5f5;

	/* Black colors */
	--black-150: #000;
	--black-140: #000203;
	--black-130: #000708;
	--black-120: #000b0e;
	--black-110: #001216;
	--black-100: #00171d;
	--black-90: #052a33;
	--black-80: #0f3e49;
	--black-70: #1d5360;
	--black-60: #306977;
	--black-50: #477f8e;
	--black-40: #6397a4;
	--black-30: #83b0bb;
	--black-20: #a8c9d2;
	--black-10: #d1e4e8;
	--black-05: #e8f0f2;

	/* Background colors */
	--bg-50: #ebebeb;
	--bg-40: #eeeeee;
	--bg-30: #f2f2f2;
	--bg-20: #f6f6f6;
	--bg-10: #fafafa;
	--white: #fff;

	/* Typography colors */
	--type-70: #252525;
	--type-60: #414141;
	--type-50: #656565;
	--type-40: #7d7d7d;
	--type-30: #999999;
	--type-20: #b5b5b5;
	--type-10: #cacaca;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

@font-face {
	font-family: 'Inter';
	src: url('/fonts/Inter-Regular.ttf');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('/fonts/Inter-Medium.ttf');
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('/fonts/Inter-SemiBold.ttf');
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}

.grecaptcha-badge {
    visibility: hidden;
}
