.customSearchableSelectSecondary {
	width: 100%;
	position: relative;
	overflow-x: hidden;
}

.loadingSpinner {
	position: absolute;
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
	z-index: 1;
}

.option {
	padding: 2px 4px;
}

.label,
.description {
	margin-bottom: 0;
}

.label {
	letter-spacing: 0.25px;
	color: var(--black-80);
}

.description {
	font-size: 13px;
	line-height: 17px;
}
