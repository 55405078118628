.header {
	width: 100%;
	height: 64px !important;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	padding: 0 24px 0 96px !important;
	background: #fff !important;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
	z-index: 1000;
}

.breadcrumb {
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
	column-gap: 24px;
	row-gap: 12px;
	margin-right: 16px;
}

.backButton {
	width: 32px !important;
	height: 32px !important;
	padding: 0 !important;
	border: 1px solid var(--bg-50) !important;
	border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.moduleName {
	font-size: 15px;
	line-height: 14px;
	margin-bottom: 0;
	color: var(--black-100);
	cursor: pointer;
}

.moduleNameSmall {
	grid-column: 2 / 3;
	font-size: 12px;
	line-height: 14px;
	margin-bottom: 0;
	color: #656565;
}

.pageName {
	font-weight: 500;
	color: var(--black-100);
}

.bullet {
	color: #b5b5b5;
}

.content {
	background: white;
}

.navStart {
	display: flex;
	align-items: center;
	gap: 40px;
}

.logo {
	width: 144px;
    height: 47px;
}

.customLogo {
	height: 47px;
	cursor: pointer;
}

.navMiddle {
	height: 100%;
	display: flex;
	gap: 40px;
}

.navLink {
	height: 100%;
	font-size: 16px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: #000;
	transition: color 0.3s;
}

.navLink:hover {
	color: var(--primary-100);
}

.navLinkActive {
	font-weight: 600;
	color: var(--primary-100);
}

.navEnd {
	display: flex;
	align-items: center;
	gap: 32px;
	user-select: none;
}

.apps {
	display: flex;
	align-items: center;
	gap: 12px;
}

.currentApp {
	width: 40px;
	height: 40px;
	display: grid;
	place-items: center;
	background: var(--bg-10);
	border-radius: 50%;
}

.currentApp img {
	width: 21px;
}

.flagIcon {
	width: 24px;
}

.invoiceResultContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.invoiceUnpaidStatus {
    background-color: #FFFBF4;
    color: #C48F3F;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 10px;
    letter-spacing: 0.5px;
    margin-left: 5px;
}

.invoiceVoidStatus {
    background-color: #FFF5F5;
    color: #FF5959;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 10px;
    letter-spacing: 0.5px;
    margin-left: 5px;
}

.invoicePaidStatus {
    background-color: #F2F8FA;
    color: #288EA5;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 10px;
    letter-spacing: 0.5px;
    margin-left: 5px;
}

.paymentResultContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	gap:10px;
}

.paymentPaidStatus {
    background-color: #F2F8FA;
    color: #288EA5;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 10px;
    letter-spacing: 0.5px;
	display:flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	cursor: pointer;
	min-width: 60px;
}

.paymentReconciledStatus {
	background-color: #F2FEFB;
    color: #1CB89B;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 10px;
    letter-spacing: 0.5px;
	display:flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	cursor: pointer;
	min-width: 60px;
	text-wrap: nowrap;
}

.paymentFailedStatus {
	background-color: #FFF5F5;
    color: #FF5959;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 10px;
    letter-spacing: 0.5px;
	display:flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	cursor: pointer;
	min-width: 80px;
}

@media print {
	.header {
		display: none;
	}

	.content {
		margin-top: 0 !important;
	}
}

.releaseBadge {
    font-size: 12px;
    font-weight: bold;
    position: relative;
    height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 12px;
}

.releaseBadge released-badge {
    position: absolute;
    top: 10;
    right: 0;
    transform: translate(50%, -50%);
}

.insightIconContainer {
    position: relative;
}

.insightIcon {
    width: 16px;
	cursor: pointer;
}

.insightIndicator {
    position: absolute;
    top: 2px;
    right: 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary-100);
}

.notificationContainer {
    position: relative;
    margin-top: 2px;
}

.notificationIcon {
	cursor: pointer;
}

.notificationIndicator {
    position: absolute;
    top: 2px;
    right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary-100);
}

.filterLabel {
    font-size: 11px;
    line-height: 16px;
    color: #4b5766;
}