.numberInput {
	width: 100%;
	position: relative;
}

.numberInput:not(:has(.titleReadonly, .titleError)):focus-within .title {
	color: var(--primary-100);
}

.title,
.loadingIcon {
	position: absolute;
	z-index: 1;
}

.title,
.loadingIcon {
	user-select: none;
	pointer-events: none;
}

.title {
	font-size: 13px;
	font-weight: 500;
	line-height: 14px;
	top: 10px;
	left: 16px;
	color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.titleError {
	color: var(--error-100);
}

.questionMarkIcon {
	margin-left: 12px;
	cursor: help;
	pointer-events: all;
}

.loadingIcon {
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
}
