.name {
	font-size: 11px;
	padding: 2px 8px;
	color: var(--caution-120);
	background: var(--caution-20);
	border-radius: 0px;
    align-self: start;
}

.new {
	background: #f3ca36;
}

.new,
.fraud {
	color: #fff;
}

.vip {
	color: var(--black-80);
	background: var(--success-100);
}

.fraud {
	background: #ea3323;
}
