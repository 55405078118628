.placesAutocomplete {
	position: relative;
	overflow: hidden;
    border: 1px solid var(--bg-20) !important;
}

.placesAutocomplete:focus-within {
	border: 1px solid var(--primary-100) !important;
}

.searchIcon {
	position: absolute;
	top: 50%;
	right: 12px;
	background: #eff2f6;
	transform: translateY(-50%);
}

.disabledSearchIcon {
	position: absolute;
	top: 50%;
	right: 12px;
	background: transparent;
	visibility: hidden;
	transform: translateY(-50%);
}

.customSelectTitle {
	position: absolute;
	top: 10px;
	left: 16px;
    font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	color: #7d7d7d;
	z-index: 1;
}
