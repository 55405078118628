.form {
	max-height: 48px;
	display: flex;
	align-items: center;
	gap: 4px;
}

.form,
.input {
	height: 48px;
}

.inputContainer {
	position: relative;
	display: grid;
	grid-template-columns: auto max-content;
}

.input {
	width: 100%;
	padding: 12px 16px;
	color: var(--black-80);
	border: 1px solid var(--bg-50);
	border-right: none !important;
	outline: none;
	transition: border 0.2s;
}

.input:hover,
.input:focus {
	border: 1px solid #7d7d7d;
}

.input,
.input::placeholder {
	font-size: 16px;
	line-height: 24px;
}

.input::placeholder {
	color: #999999;
}

.closeButton,
.removeResultButton {
	padding: 3px;
	background: none;
	border: none;
	outline: none;
	border-radius: 4px;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s, opacity 0.2s, background 0.3s;
	cursor: pointer;
}

.closeButton {
	position: absolute;
	top: 50%;
	right: 64px;
	background: #fff;
	transform: translateY(-50%);
}

.closeButton:hover,
.removeResultButton:hover {
	background: rgb(240, 240, 240);
}

.closeButton img,
.removeResultButton img {
	width: 24px;
}

.closeButtonVisible {
	visibility: visible;
	opacity: 1;
}

.searchDropdown {
	max-width: 100% !important;
}

.searchContainer {
	padding-bottom: 24px;
}

.dropdownTitle {
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	word-break: break-all;
	margin-bottom: 16px;
	color: var(--black-60);
}

.dropdownTitle em {
	font-weight: 600;
	font-style: normal;
	color: var(--primary-100);
}

.resultsContainer {
	max-height: 296px;
	position: relative;
	padding-right: 8px;
	overflow-y: auto;
}

.resultsContainer::-webkit-scrollbar {
	width: 8px;
	background: transparent;
}

.resultsContainer::-webkit-scrollbar-thumb {
	background: #cacaca;
	border-radius: 21px;
}

.spinner {
	width: 100%;
	height: 278px;
	display: grid;
	place-items: center;
	background: rgba(255, 255, 255, 0.6);
}

.results {
	padding: 0;
	margin-bottom: 0;
	border: 1px solid var(--bg-30);
}

.result {
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	list-style: none;
	word-break: break-all;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
	color: var(--primary-100);
	transition: background 0.3s;
}

.result:hover {
	background: var(--bg-10);
}

.result:hover .removeResultButton {
	visibility: visible;
	opacity: 1;
}

.result:not(:last-of-type) {
	border-bottom: 1px solid var(--bg-30);
}

.pagination {
	position: sticky;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;
	padding: 12px;
	background: #fff;
	border: 1px solid var(--bg-30);
	user-select: none;
}

.paginationButton {
	height: auto !important;
	padding: 0;
	background: none !important;
	border: none !important;
	box-shadow: none !important;
}

.paginationButton:first-of-type img {
	rotate: 180deg;
}

.resultCount {
	font-size: 16px;
	font-weight: 600;
	color: var(--black-80);
}

.noData {
	text-align: center;
	padding: 32px;
	border: 1px solid var(--bg-30);
}

.iconContainer {
	width: 96px;
	height: 96px;
	display: inline-grid;
	place-items: center;
	margin-bottom: 16px;
	background: var(--bg-10);
	border-radius: 50%;
}

.noDataTitle {
	font-size: 24px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 8px;
	color: var(--black-80);
}

.noDataDescription {
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 0;
	color: #656565;
}

.noRecentSearch {
	text-align: center;
	padding: 50px 0;
}

.noRecentSearch p {
	font-size: 20px;
	margin-bottom: 0;
}

.searchButton {
	width: 48px;
	height: 48px !important;
	padding: 0 !important;
	background: var(--primary-100) !important;
	border: none !important;
	border-radius: 0 !important;
}

.filterButton {
	height: 48px !important;
	display: flex !important;
	align-items: center;
	gap: 8px;
	padding: 12px 16px !important;
	border: 1px solid var(--bg-50) !important;
	border-radius: 0 !important;
}

.searchApplied {
	background: var(--black-80) !important;
	border: 1px solid var(--black-80) !important;
}

.searchApplied .filterIcon {
	fill: #fff;
}

.searchApplied .filterText {
	color: #fff;
}

.filterIcon,
.closeIcon {
	width: 24px;
	height: 24px;
	transition: fill 0.3s;
}

.filterIcon {
	fill: var(--black-80);
}

.closeIcon {
	fill: var(--black-80);
}

.closeIconWhite {
	fill: #fff;
}

.filterText {
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	color: var(--black-80);
	transition: color 0.3s;
}

.activeFilterIndicator {
	width: 13px;
	height: 13px;
	position: absolute !important;
	top: 0;
	right: 0;
	background: var(--primary-100);
	border: 2px solid #fff;
	border-radius: 50%;
	opacity: 0;
	visibility: hidden;
	transform: translate(50%, -50%);
	transition: visibility 0.3s, opacity 0.3s;
}

.activeFilterIndicatorVisible {
	visibility: visible;
	opacity: 1;
}

.dropdown {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 0;
    position: absolute;
    z-index: 999;
    background: white;
    margin-top: 4px;
    opacity: 0;
    transform: translateY(-12px);
    transition: opacity 0.1s ease, transform 0.2s ease;
    visibility: hidden;
    width: max-content;
    right: 0;
}

.dropdown.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.dropdownContainer {
    position: relative;
}