.uploadContainer {
	display: flex;
	flex-direction: column;
    gap: 12px;
}

.item {
	flex: 1;
	padding: 12px;
	border: 1px solid #d9d9d9;
	border-radius: 0px;
	margin-right: 24px;
	display: flex;
	flex-direction: column;
}

.item:last-of-type {
	margin-right: 0;
}

.uploadItem {
	margin-top: 24px;
	padding: 12px;
	border: 1px solid #d9d9d9;
	border-radius: 0px;
	display: flex;
	flex-direction: column;
}

.uploadText {
	font-weight: bold;
}

.file {
	color: #a09f9f;
	font-weight: normal;
	margin-top: 12px;
}

.output {
	margin-top: 24px;
	background: #d8d8d8;
	padding: 12px;
	border-radius: 0px;
	max-height: 164px;
	overflow-y: auto;
}

.outputRow {
	margin-bottom: 6px;
}

.import {
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.countContainer {
    display: flex;
    gap: 12px;
}

.count {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 2px 8px;
    color: #414141;
    background: var(--bg-30);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}


.downloadIcon {
	fill: #fff;
	width: 16px;
	height: 16px;
}

.countSelect {
	padding: 4px 16px;
	border: 1px solid #f5f5f5;
	background: #f9f9f9;
	border-radius: 48px;
	margin: 0 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	color: #292929;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	align-self: center;
	cursor: pointer;
}

.customerName {
	font-size: 12px;
	font-weight: bold;
}

.customerPhone {
	font-size: 12px;
	color: #000;
	white-space: nowrap;
}

.customerLocation {
	font-size: 11px;
}

.sourceTag {
	padding: 2px 12px;
	margin-top: 4px;
	align-self: flex-start;
	font-size: 11px;
}

.invoiceNoContainer {
	display: flex;
	align-items: center;
	margin-top: 4px;
}

.rowSelection,
.duplicateOrderIndicator {
	display: flex;
	align-items: center;
}

.rowSelection {
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	border: 1px solid var(--bg-30);
	border-bottom: none;
    gap: 12px;
}

.duplicateOrderIndicator {
	gap: 16px;
}

.duplicateOrderIndicator p {
	color: #000;
}

.yellowRectangle {
	width: 16px;
	height: 16px;
	background: #ffe4b3;
}

.rowSelectionText {
	color: var(--black-100);
}

.rowSelectionText span {
	color: var(--error-100);
	cursor: pointer;
}

.deliveryPartnerNameColumn,
.inTransitSubTabButtonContent {
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
}

.deliveryPartnerNameColumn {
	gap: 10px;
}

.inTransitSubTabButton {
	display: flex !important;
	align-items: center;
}

.inTransitSubTabButtonContent {
	gap: 12px;
}

.customerNameContainer {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.customerPhoneContainer {
	display: flex;
	align-items: center;
	gap: 2px;
}

.duplicateOrdersButton {
	height: auto !important;
	display: flex !important;
	align-items: center;
	gap: 10px;
	padding: 8px !important;
	border: 1px solid var(--bg-50) !important;
	border-radius: 0 !important;
}

.duplicateOrdersButton:hover,
.duplicateOrdersButtonActive {
	background: #e8f0f2 !important;
	border: 1px solid #e8f0f2 !important;
}

.duplicateOrderIndicatorText {
	font-weight: 500;
	line-height: 16px;
	color: #000000;
}

.duplicateOrderCount,
.duplicatePhoneCount {
	font-size: 12px;
	font-weight: 600;
	line-height: 12px;
	padding: 3px 6px;
	color: #fff;
}

.duplicateOrderCount {
	background: var(--primary-100);
}

.duplicatePhoneCount {
	color: #202020;
	background: #f7b44f;
}

.deliveryStatus {
	display: flex;
	align-items: center;
	margin-top: 8px;
	text-align: left;
}

.reason {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 30ch;
}

.datesContainer {
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.dateBadge {
	font-size: 11px;
	display: flex;
	align-items: center;
	gap: 6px;
	align-self: flex-start;
	padding: 2px 8px;
	border-radius: 12px;
	white-space: nowrap;
}

.dateBadge span {
	font-weight: bold;
	color: black;
	font-size: 10px;
}

.highlighted {
	background: var(--primary-50);
	color: white;
}

.dropdown {
    padding: 16px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 0;
    position: absolute;
    z-index: 999;
    background: white;
    margin-top: 4px;
    opacity: 0;
    right: 0;
    transform: translateY(-12px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    visibility: hidden;
}

.dropdown.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.dropdownContainer {
    position: relative;
}

.tableButtons {
    display: flex;
    gap: 12px;
}

.divider {
    background: #EBEBEB;
    height: 1px;
    width: 100%;
}

.selectedDeliveryPartner {
    background: #e3f3f6 !important;
}

.badge {
	color: #00171d;
	background: #e8f0f2;
	padding: 2px 6px;
	font-size: 10px;
    align-self: flex-start;
}

.imageContainer {
	width: 44px;
	height: 44px;
	border-radius: 4px;
	overflow: hidden;
}