.mapLayersControlContainer {
	padding: 8px 14px;
	background-color: white;
	display: flex; 
	align-items: center;
	column-gap: 10px;
	font-size: 12px;
}

.trafficLegendContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 6px;
	border-left: 1px solid #dadce0;
	border-right: 1px solid #dadce0;
	padding: 0 10px;
}