.customerDataSection,
.customerData {
	display: grid;
	grid-auto-rows: max-content;
	gap: 6px;
}

.customerDataSection {
	max-height: calc(100vh - 242px);
	overflow-y: auto;
}

.customerDataSection::-webkit-scrollbar {
	width: 8px;
	height: 10px;
	background: transparent;
}

.customerDataSection::-webkit-scrollbar-thumb {
	background: #d2d2d2;
	border-radius: 30px;
}

.customerData {
	padding: 12px;
	border: 1px solid #ebebeb;
	border-radius: 8px;
}

.customerDataOngoingOrders {
	background: var(--caution-05);
	border: 1px solid var(--caution-05);
    max-height: 312px;
    overflow-y: auto;
}

.customerDataOngoingOrders::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background: transparent;
}

.customerDataOngoingOrders::-webkit-scrollbar-thumb {
	background: #999999;
	border-radius: 21px;
}


.editButtonContainer {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.customerLabel {
	font-size: 12px;
	margin: 0;
	color: #6e7c91;
}

.customerDetailSecondary,
.customerDetail {
	font-size: 12px;
	margin: 0;
	color: #000000;
	display: flex;
	align-items: center;
}

.customerDetail {
	display: flex;
	align-items: center;
	gap: 12px;
}

.customerDetailSecondary {
	max-width: 336px;
	word-wrap: break-word;
}

.discountInfoOnCart {
	display: flex;
	color: #18a188;
	font-size: 12px;
}

.customerDataTitleContainer {
	display: flex;
	align-items: center;
	gap: 8px;
}

.customerDataTitle {
	font-size: 12px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 0;
	color: #000000;
}

.customerDataCount {
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: 0.25px;
	padding: 2px 14px;
	color: #242529;
	background: var(--bg-20);
	border-radius: 0px;
}

.customerDataCountOrange {
	color: var(--black-100);
	background: var(--caution-100);
}

.orderDetail {
	display: grid;
	gap: 8px;
	padding: 12px 12px;
}

.orderDetail:not(:last-of-type) {
	border-bottom: 1px solid #ebebeb;
}

.orderDetail p {
	margin: 0;
}

.orderDetailRounded {
	background: #ffffff;
	border: none !important;
	border-radius: 0px;
}

.orderTitleContainer {
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.orderPriceContainer {
	display: grid;
	grid-template-columns: 1fr auto max-content;
	gap: 4px;
}

.orderTitleContainer,
.orderPriceContainer {
	align-items: center;
    font-size: 12px;
}

.orderLinkContainer {
	display: flex;
	align-items: center;
	gap: 4px;
}

.orderTitle {
	font-weight: 500;
    font-size: 12px;
}

.orderStatus {
	font-size: 12px;
	font-weight: 500;
	color: var(--primary-100);
}

.orderStatusContainer {
	padding: 4px 12px;
	background: rgba(40, 142, 165, 0.06);
	border-radius: 0px;
}

.orderStatusPending {
	background: var(--caution-10);
}

.orderStatusPending p {
	color: var(--caution-100);
}

.orderStatusOnHold {
	background: #ffcdcd;
}

.orderStatusOnHold p {
	color: black;
}

.orderStatusApproved {
	background: var(--success-10);
}

.orderStatusApproved p {
	color: var(--success-120);
}

.orderStatusProcessing {
	background: #efeeff;
}

.orderStatusProcessing p {
	color: #8075f9;
}

.orderStatusShipped {
	background: #e9efff;
}

.orderStatusShipped p {
	color: #5780ea;
}

.orderStatusInTransit {
	background: var(--caution-10);
}

.orderStatusInTransit p {
	color: var(--caution-150);
}

.orderStatusDelivered {
	background: var(--primary-05);
}

.orderStatusDelivered p {
	color: var(--primary-100);
}

.orderStatusFlagged {
	background: var(--error-10);
}

.orderStatusFlagged p {
	color: var(--error-90);
}

.orderStatusCanceled {
	background: var(--error-10);
}

.orderStatusCanceled p {
	color: var(--error-120);
}

.orderPrice {
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.25px;
	color: #242529;
}

.orderDate {
	font-size: 12px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.25px;
	color: #7d7d7d;
}

.tooltip {
	display: grid;
	gap: 4px;
}

.tooltip p {
	font-size: 12px;
	line-height: 18px;
	color: #ffffff;
	margin: 0;
}

.results {
	display: grid;
	gap: 8px;
}

.barSpinnerContainer {
	height: 25px;
}