.title {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 12px;
	color: var(--black-100);
}

.inputGroup {
	display: flex !important;
	justify-content: flex-start;
	flex-wrap: wrap;
	row-gap: 12px;
}

.inputFlex {
	display: flex;
	gap: 8px;
}

.inputGrid {
	display: grid;
	gap: 12px;
}

.inputTitle {
	font-size: 14px !important;
	font-weight: 500;
	line-height: 16px !important;
	color: #656565 !important;
}

.inputTitle:not(.ant-radio-group) {
	margin: 0 8px 0 0 !important;
}

.buttons {
	position: sticky;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	padding: 10px 0 24px 0;
	background: #fff;
	z-index: 1001;
}

.resetButton {
	border: 1px solid var(--error-100) !important;
}

.resetButton .buttonText {
	color: var(--error-100);
}

.buttonText {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}
