.modal {
	position: relative;
	padding: 24px;
	background: #fff;
	box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
}

.titleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
}

.modalTitle {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 0;
	color: var(--black-100);
}

.closeIcon {
	width: 24px;
	height: 24px;
	stroke: #14142b;
	transition: stroke 0.2s;
	cursor: pointer;
}

.closeIcon:hover {
	stroke: #444;
}

.inputGrid {
	display: grid;
	gap: 24px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	margin-top: 24px;
}

.locationMarker {
	border-radius: 50%;
	width: 14px;
	height: 14px;
	background: #278ea5;
	border: 2px solid white;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	position: absolute;
	transform: translate(-50%, -50%);
}
