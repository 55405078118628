.iconContainer {
    background: #278ea5;
    padding: 10px;
    position: fixed;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    bottom: 24px;
    right: 24px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.badge {
    background: #ff4d4f;
    color: white;
    border-radius: 6px;
    padding: 2px 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-size: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.dismissButton {
    background: black;
    padding: 2px 6px;
    position: absolute;
    border-radius: 8px;
    top: -12px;
    left: -12px;

}

.iconContainer .dismissButton {
    visibility: hidden;
}

.iconContainer:hover .dismissButton {
    visibility: visible;
}