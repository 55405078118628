.page {
	width: 210mm;
	height: 297mm;
	padding-top: 6mm;
    padding-bottom: 6mm;
	display: flex;
	flex-direction: column;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	line-height: 1;
	page-break-before: always;
	background: white;
    display: none;
	overflow: hidden;
	position: relative;
	color: black;
    font-family: 'NotoNastaliqUrdu', sans-serif;
}

.rectangle {
    border: 2px solid black;
    height: 20mm;
    width: 80mm;
}

.smallRectangle {
    border: 2px solid black;
    height: 15mm;
    width: 70mm;
}

.amount {
    padding: 8px 12px;
    border: 2px solid black;
    padding-bottom: 12px;
}

.divider {
    height: 2px;
    width: 100%;
    background-color: black;
    margin: 12px 0;
}

.box {
    border: 2px solid black;
    align-self: center;
    margin-top: 20px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
}

.pageNumber {
	position: absolute;
	font-size: 10px;
	color: black;
	font-weight: bold;
	right: 48px;
	bottom: 24px;
}

.circle {
    border: 2px solid black;
    height: 20mm;
    width: 20mm;
    border-radius: 50%;
}

.dotted {
    border-bottom: 2px dotted black;
}

.dottedClear {
    border-bottom: 2px dotted black;
    color: white;
}

.square {
    width: 100%;
    aspect-ratio: 1 / 1;
    max-width: 96px;
    max-height: 96px;
    border: 1px solid black;
}

@media print {
	.page {
		display: flex;
	}
}
