.modalHeader {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	gap: 1rem;
}

.modal {
	position: relative;
}

.selectedRows {
	display: flex;
	justify-content: flex-end;
}

.selectRowsPrompt {
	display: flex;
	align-items: center;
	margin: 6px 0;
}