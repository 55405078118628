.uploadContainer {
	display: flex;
	flex-direction: column;
}

.item {
	flex: 1;
	padding: 12px;
	border: 1px solid #d9d9d9;
	border-radius: 0px;
	margin-right: 24px;
	display: flex;
	flex-direction: column;
}

.item:last-of-type {
	margin-right: 0;
}

.uploadItem {
	margin-top: 24px;
	padding: 12px;
	border: 1px solid #d9d9d9;
	border-radius: 0px;
	display: flex;
	flex-direction: column;
}

.uploadText {
	font-weight: bold;
}

.file {
	color: #a09f9f;
	font-weight: normal;
	margin-top: 12px;
}

.output {
	margin-top: 24px;
	background: #d8d8d8;
	padding: 12px;
	border-radius: 0px;
	max-height: 164px;
	overflow-y: auto;
}

.outputRow {
	margin-bottom: 6px;
}

.import {
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
