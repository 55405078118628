.notificationsTray {
	width: 432px;
	max-height: 60vh;
	overflow-y: auto;
	padding: 0 !important;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.notification {
	display: flex !important;
	align-items: center !important;
	padding: 10px 24px !important;
}

.unreadIndicator {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #278EA5;
	transition: 200ms linear;
}

.unreadIndicator:hover {
	box-shadow: 0 0 0 2px #278ea5b2;
}

.notificationContainer {
	display: flex;
	flex-direction: column;
	margin-left: 24px;
	width: 350px;
	overflow: hidden;
}

.loadingContainer {
	width: 432px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.message {
	font-weight: bold;
	word-wrap: break-word;
	white-space: normal;
	color: #000;
}

.description {
	font-size: .8rem;
	font-weight: normal;
	line-height: 19px;
	word-wrap: break-word;
	white-space: normal;
	color: #656565;
	display: flex;
	align-items: center;
	margin-top: 5px;
}

.date {
	margin-top: 5px;
	margin-left: auto;
	font-size: .8rem;
	color: #278EA5;
}

.topContainer{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.notificationHeader {
	position: sticky;
	top: 0;
	background: white;
	z-index: 20;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
}

.searchContainer{
	width: 100%;
}

.typeFilter {
    width: 100%;
    flex: 1;
    display: flex !important;
}

.allReadBtn {
    border: none;
    box-shadow: none;
    font-size: 11px;
}

.textInput {
    padding: 8px 16px 8px 48px;
    height: 48px;
}

.tag {
    font-size: 10px;
    align-self: flex-start;
    padding: 2px 8px;
    margin-bottom: 8px;
}