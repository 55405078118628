.spin {
	align-self: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 96px;
}

.back {
	font-size: 16px;
}

.id {
	color: #278ea5;
	background: #eff2f6;
	border-radius: 0px;
	padding: 12px 32px;
	font-size: 16px;
}

.statContainer {
	display: flex;
	flex-direction: column;
	background: #fafafa;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
	padding: 16px 24px;
	flex: 1;
	margin-right: 40px;
}

.statContainer:last-of-type {
	margin-right: 0;
}

.statTitle {
	color: #6e7c91;
	font-size: 16px;
}

.statValue {
	color: black;
	font-size: 16px;
}

.headerContainer {
	position: fixed;
	left: 0;
	right: 0;
	width: 100%;
	background: white;
	border-bottom: 1px solid #d9d9d9;
	z-index: 10;
}

.tabContainer {
	display: flex;
	gap: 24px;
	margin-bottom: 20px;
	margin-top: 20px;
	padding: 0 50px 0 96px;
}

.tab {
	padding: 0 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: bold;
	color: #6e7c91;
	letter-spacing: 0.75px;
}

.tab:hover {
	cursor: pointer;
	font-weight: bold;
	color: black;
}

.tabSelected {
	padding: 0 38px;
	display: flex;
	font-weight: bold;
	flex-direction: column;
	align-items: center;
	color: black;
	letter-spacing: 0.75px;
}

.tabSelected:hover {
	cursor: pointer;
}

.indicator {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
}

.indicatorGone {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
	visibility: hidden;
}

.unassigned {
	color: rgba(0, 0, 0, 0.25);
}

.lifoTag {
	display: flex;
	align-items: center;
	margin-left: 24px;
}

.lifoTag h3 {
	margin: 0 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 38px;
	letter-spacing: 0.75px;
	color: #00ba88;
}

.idContainer {
	background: #eff2f7;
	border-radius: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px 12px;
}

.idContainer h3 {
	font-weight: normal;
	font-size: 20px;
	line-height: 38px;
	letter-spacing: 0.75px;
	color: #278ea5;
	margin: 0;
}

.idStatusContainer {
	display: flex;
	align-items: center;
}

.idStatusMessageContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
}

.routeItemMessage {
	text-align: left;
	padding: 4px;
	font-size: 12px;
	border: 1px solid var(--primary-100);
	background: rgba(40, 142, 165, 0.1);
}

.routeItemError {
	text-align: left;
	padding: 4px;
	font-size: 12px;
	border: 1px solid #ff4d4f;
	background: rgba(255, 77, 79, 0.1);
}

.routeInfo {
	display: flex;
	margin-bottom: 12px;
	align-items: center;
}

.travelInfoContainer {
	display: flex;
	align-items: center;
}

.travelInfo {
	margin-right: 16px;
}

.travelInfo div {
	margin-bottom: -1px;
}

.orderInfo {
	margin-left: 16px;
	font-size: 13px;
	margin-right: auto;
}

.orderInfo div {
	margin-bottom: -1px;
}

.draggableRow {
	background-color: #278ea522;
}

.rowGhosting {
	background-color: #278ea522;
}

.dragHand {
	position: relative;
}

.dragHand::after {
	content: '||';
	position: absolute;
	left: -16px;
	top: calc(50% - 12px);
}

.actionIcon {
	cursor: pointer;
	color: red;
}

.weightIndicator {
	margin-left: 10px;
	color: #00ba88;
}

.overWeightIndicator {
	margin-left: 10px;
	color: red;
}

.overWeight {
	background-color: rgba(255, 0, 0, 0.11);
}

.orderInfoRow {
	display: flex;
	margin-right: 12px;
}

.orderInfoHeading {
	font-weight: bold;
	margin-right: 6px;
}

.locations {
	font-size: 13px;
}

.location {
	display: inline-block;
	font-size: 12px;
}

.badge {
	color: black;
	background: #eeeeee;
	align-self: flex-start;
	margin-bottom: 6px;
	padding: 2px 6px;
	font-size: 11px;
	font-weight: bold;
	border-radius: 0px;
}

.locationIndicator {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #1890ff;
	display: inline-block;
	margin-right: 6px;
	align-self: center;
}

.status {
	display: flex;
	align-items: center;
	align-self: center;
	justify-self: center;
	justify-content: center;
	color: white;
	padding: 4px 12px;
	border-radius: 0px;
	font-size: 12px;
	white-space: nowrap;
}

.logContainer {
	margin-top: 95px;
	margin-bottom: 45px;
}

.deliveryPlanHistoryItem {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #c4c4c460;
	padding: 16px 32px;
}

.messageContainer {
	margin-left: 64px;
}

.message {
	font-size: 15px;
	line-height: 20px;
	color: #64748b;
}

.customMessage {
	font-size: 13px;
	color: #64748bc4;
}

.deliveryPlanHistoryItemDate {
	color: #278ea5;
	font-size: 17px;
}

.time {
	color: #64748b;
	font-size: 14px;
}

.requestProgress {
	width: 100%;
	height: 4px;
	position: relative;
	overflow: hidden;
	background: linear-gradient(to right, #278ea5, #09796b);
}

.bar {
	position: absolute;
	width: calc(100% * 2 / 7);
	height: 100%;
	display: flex;
	animation: move 2s linear infinite;
	background-color: #d9d9d9;
}

@keyframes move {
	0% {
		transform: translate(-100%, 0);
	}
	100% {
		transform: translate(calc(7 / 2 * 100%), 0);
	}
}

.routeError {
	justify-self: center;
	font-size: 10px;
	margin-bottom: 12px;
	color: white;
	background: #ff4d4f;
	border-radius: 0px;
	padding: 2px 10px;
    text-align: center;
}

.vehicleInfo {
	margin-left: 10px;
}

.infoTitle {
	font-weight: bold;
}

.infoValue {
	margin-left: 5px;
}

.routeMenuContainer {
	display: flex;
	flex-direction: column;
}

.internalNotes {
	margin-left: 8px;
	margin-bottom: 4px;
}

.travelRouteInfo {
	font-size: 13px;
}

.travelInfoTitle {
	color: black;
	font-weight: 600;
}

.locationContainer {
	display: flex;
	flex-direction: column;
}

.nonGeneratedItems {
	margin: 20px 0px;
	padding: 10px 0px;
}

.heading {
	font-size: 20px;
}

