.subscriptionUpdateBar {
	width: 100vw;
	height: 50px;
	position: fixed;
	z-index: 1100;
	background-color: #F2F8FA;
}

.subscriptionUpdateContainer {
	width: 100%;
	height: 100%;
	padding: 0px 30px;
}

.subscriptionMainUpdate {
	font-size: 14px;
	font-weight: 600;
	padding: 0px;
	margin: 0px;
}

.subscriptionMainUpdatePayable {
	font-size: 14px;
	font-weight: 600;
	padding: 0px;
	margin: 0px;
	color: #288EA5;
}

.subscriptionMainUpdateDays {
	font-size: 14px;
	font-weight: 600;
	padding: 0px;
	margin: 0px;
	color: #DCA047;
}

.subscriptionMainUpdateCanceled {
	font-size: 14px;
	font-weight: 600;
	padding: 0px;
	margin: 0px;
	color: #FF5959;
}

.notificationRedText {
	font-size: 14px;
	font-weight: 600;
	padding: 0px;
	margin: 0px;
	color: #FF5959;
}

.notificationGreenText {
	font-size: 14px;
	font-weight: 600;
	padding: 0px;
	margin: 0px;
	color: #4BEBCD;
	cursor: pointer;
}

.notificationCarousel {
	width: 100%;
	height: 100%;
	margin-top: 9px;
}

.notificationCarouselDots {
	top: 34px;
}

.notificationContainer {
	display: flex !important;
	gap: 14px;
	width: 100%;
}

.contentContainer {
	width: 100%;
	display: flex !important;
	justify-content: space-between;
}

.notificationButtonContainer {
	display: flex;
	align-items: center;
	gap: 14px;
}

.closeIcon {
	width: 16px;
	height: 16px;
	margin: auto 0;
	stroke: var(--black-70);
	cursor: pointer;
	transition: stroke 0.2s;
}

.closeIcon:hover {
	stroke: var(--black-50);
}