.deliverySelectContainer {
	background: var(--bg-20);
	border-radius: 0px;
	height: 64px;
	border: 1px solid var(--bg-20);
	padding: 6px 3px;
	position: relative;
}

.deliverySelectContainer:focus-within {
	border: 1px solid var(--primary-100) !important;
}

.selectContainer {
	display: flex;
	align-items: center;
}

.titleContainer {
	padding: 0px 12px 0px 12px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
}

.title {
    font-size: 12px;
    font-weight: 500;
    color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.error {
	background: var(--error-10);
	border: 1px solid var(--error-100);
}
