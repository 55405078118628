.select {
	width: 100%;
	position: relative;
}

.select:not(:has(.titleError)):focus-within .title {
	color: var(--primary-100);
}

.title,
.chevronDown,
.icon {
	position: absolute;
	z-index: 1;
}

.chevronDown,
.icon,
.title {
	user-select: none;
	pointer-events: none;
}

.title {
	font-size: 12px;
	font-weight: 500;
	top: 8px;
	left: 16px;
	color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.titleError {
	color: var(--error-100);
}

.questionMarkIcon {
	margin-left: 12px;
	cursor: help;
	pointer-events: all;
}

.chevronDown,
.icon {
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
}

.chevronDown {
	width: 24px;
	height: 24px;
}

.tagsTitle {
	font-size: 14px;
	font-weight: 500;
	margin: 12px 0 7px 0;
	color: var(--black-100);
}

.tags {
	display: flex;
	flex-wrap: wrap;
	row-gap: 4px;
	column-gap: 8px;
}
