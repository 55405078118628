.customSelectSecondary {
	position: relative;
	background: var(--bg-20);
}

.title,
.chevronDown {
	position: absolute;
	z-index: 1;
}

.title {
	top: 8px;
	left: 16px;
    font-size: 12px;
    font-weight: 500;
    color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.chevronDown {
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
}
