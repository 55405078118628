.progressContainer {
	align-self: center;
	margin-left: auto;
	margin-right: auto;
    margin-bottom: 30px !important;
}

.formContainer {
	width: 100%;
	align-self: center;
	margin-left: auto;
	margin-right: auto;
}

.tabContainer {
	align-self: center;
	display: flex;
	margin-right: auto;
	height: 40px;
}

.tab {
	padding: 0 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: bold;
	color: #6e7c91;
	letter-spacing: 0.75px;
}

.tab:hover {
	cursor: pointer;
	font-weight: bold;
	color: black;
}

.tabSelected {
	padding: 0 38px;
	display: flex;
	font-weight: bold;
	flex-direction: column;
	align-items: center;
	color: black;
	letter-spacing: 0.75px;
}

.tabSelected:hover {
	cursor: pointer;
}

.indicator {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
}

.indicatorGone {
	background: black;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 4px;
	visibility: hidden;
}

.selectedVendorContainer {
	display: flex;
	flex-wrap: wrap;
	margin-top: 12px;
	gap: 12px;
}

.selectedVendor {
	border-radius: 8px;
	background: #eff2f6;
	padding: 4px 8px;
	font-weight: bold;
	color: black;
	display: flex;
	align-items: center;
}

.removeVendor {
	font-size: 13px;
	margin-right: 8px;
	cursor: pointer;
	color: #278ea5;
}