.titleVisible {
	height: 64px;
}

.title {
    margin-bottom: -0.85px;
    font-size: 12px;
    font-weight: 500;
    color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.customSearchableSelectDropdown {
	max-height: 264px;
	overflow-y: auto;
}

.customSearchableSelectDropdownSpinner {
	padding: 5px 12px;
}

.customSearchableSelectDropdownOption {
	display: flex;
	justify-content: space-between;
	padding: 5px 20px 5px 12px;
	transition: background 0.3s ease;
	cursor: pointer;
}

.customSearchableSelectDropdownOption p {
	margin: 0;
}

.customSearchableSelectDropdownOption:hover {
	background: #f5f5f5;
}

.customSearchableSelectDropdownOptionActive,
.customSearchableSelectDropdownOptionActive:hover {
	background: #d8e5e6;
}

.customSearchableSelectDropdown::-webkit-scrollbar {
	width: 8px;
	height: 10px;
	background: transparent;
}

.customSearchableSelectDropdown::-webkit-scrollbar-thumb {
	background: #d3d3d3;
	border-radius: 21px;
}
