.buttons {
	height: 64px;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 16px;
	z-index: 10;
}

.buttonsShort {
	height: 62px;
}

.button,
.pageSizeChanger,
.pageSizeSelect {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.button {
	font-size: 16px !important;
	font-weight: 600 !important;
	min-width: 40px;
	height: 40px !important;
	gap: 8px;
	padding: 8px 16px !important;
	color: var(--black-80) !important;
	border: 1px solid var(--bg-50) !important;
	border-radius: 0 !important;
}

.noPadding {
	padding: 0 !important;
}

.pageSizeChanger {
	gap: 12px;
	margin-left: 12px;
}

.pageSizeChanger p {
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	color: var(--black-80);
	margin-bottom: 0 !important;
}

.pageSizeSelect {
	gap: 8px;
	padding: 12px;
	border: 1px solid #cacaca;
	user-select: none;
	cursor: pointer;
}

.totalContainer {
	height: 40px;
	display: flex;
	align-items: center;
	color: var(--black-80);
	border: 1px solid #EBEBEB;
	padding: 12px;
}

.total {
	margin-left: 12px;
	font-weight: bold;
	color: var(--black-80);
}

@media print {
	.buttons {
		display: none !important;
	}
}