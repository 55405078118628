.container {
	display: flex;
	flex-direction: column;
}

.dateTimePreference {
	padding-left: 26px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
}

.infoText {
    font-size: 12px;
    margin-bottom: 24px;
}