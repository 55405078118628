.mapContainer {
	width: 100%;
	position: relative;
}

.vehicleInfo {
	padding: 12px;
}

.dialogTitle {
	color: #278ea5;
	font-size: 16px;
	font-weight: bold;
	white-space: nowrap;
}

.valueTitle {
	color: black;
	font-size: 14px;
	margin-right: 4px;
}

.value {
	color: #6e7c91;
	font-size: 14px;
}

.dialogInfoContainer {
	display: flex;
	flex-direction: column;
	margin-top: 12px;
}

.activeIndicator {
	margin-top: 2px;
	height: 5px;
	width: 13px;
	border-radius: 10px;
	background-color: #27ae60;
}

.inactiveIndicator {
	margin-top: 2px;
	height: 5px;
	width: 13px;
	border-radius: 10px;
	background-color: #4f4f4f;
}

.inMaintenanceIndicator {
	margin-top: 2px;
	height: 5px;
	width: 13px;
	border-radius: 10px;
	background-color: #ff5959;
}

.assignedIndicator {
	margin-top: 2px;
	height: 5px;
	width: 13px;
	border-radius: 10px;
	background-color: #00ba88;
}

.unassignedIndicator {
	margin-top: 2px;
	height: 5px;
	width: 13px;
	border-radius: 10px;
	background-color: #9295a3;
}

.filterControl {
	position: absolute;
	top: 32px;
	left: 10px;
}

.filterControlIcon {
	background-color: white;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	padding: 8px;
	cursor: pointer;
	box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.filterOptions {
	display: flex;
	flex-direction: column;
}

.filterOption {
	margin-bottom: 10px;
}

.filterOption:last-of-type {
	margin-bottom: 0px;
}
