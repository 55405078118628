.modal {
	position: relative;
	padding: 24px;
	background: #fff;
	box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
}

.titleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
}

.modalTitle {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 0;
	color: var(--black-100);
}

.closeIcon {
	width: 24px;
	height: 24px;
	stroke: #14142b;
	transition: stroke 0.2s;
	cursor: pointer;
}

.closeIcon:hover {
	stroke: #444;
}

.inputGrid {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 16px;
	margin-bottom: 16px;
}

.searchInput {
	grid-column: 1 / 3;
}

.pickUpLocationSelect {
	max-width: 207px;
}

.checkboxesContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 16px;
	margin-bottom: 16px;
}

.checkboxes {
	display: flex;
	flex-wrap: wrap;
}

.checkboxes label > :nth-child(2),
.rowSelectionText {
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	margin-bottom: 0;
	color: #656565;
}

.checkboxes label > :nth-child(2) {
	white-space: nowrap;
}

.rowSelectionText span {
	color: var(--error-100);
	cursor: pointer;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	margin-top: 24px;
}

.columnCheckboxes {
	display: grid;
	gap: 12px;
}

.link {
	font-weight: 600;
}

.badge {
	font-weight: 600;
	line-height: 18px;
	display: inline-block;
	padding: 6px 8px;
	margin-bottom: 8px;
	color: var(--black-80);
	background: var(--bg-20);
}

.pickUpLocations {
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	border-radius: 16px;
	border: 1px solid #eff2f6;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	--antd-wave-shadow-color: #278ea5;
	transition: all 0.3s;
}

.pickUpLocation {
	padding: 6px;
}

.pickUpLocation:hover {
	cursor: pointer;
	background: #d9d9d98c;
	border-radius: 4px;
}

.locationsList {
	max-height: 96px;
	overflow-y: auto;
}
