.customSelectContainer {
	position: relative;
	width: 100%;
}

.customSelectTitle {
	position: absolute;
	top: 7px;
	left: 17px;
    font-size: 12px;
    font-weight: 500;
    color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	z-index: 1;
}