.apps {
	position: relative;
}

.toggleButton {
	width: 40px;
	height: 40px !important;
	display: grid !important;
	place-items: center;
	padding: 0 !important;
	background: #fff !important;
	border: 1px solid var(--bg-50);
	border-radius: 50% !important;
}

.toggleButton:hover,
.toggleButton:focus {
	background: var(--bg-30) !important;
	border-color: var(--bg-30) !important;
}

.toggleButton img {
	width: 24px;
}

.dropdown {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 8px;
	padding: 16px;
	margin-top: 3px;
	background: #ffffff;
	border: 1px solid var(--bg-20);
	box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.12);
}

.button {
	height: auto !important;
	padding: 12px 24px !important;
	border: 1px solid var(--bg-30) !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	transition: background 0.3s, border 0.3s !important;
}

.button:hover {
	background: var(--primary-05) !important;
	border: 1px solid var(--primary-100) !important;
}

.button:hover .iconContainer {
	background: #fff;
}

.iconContainer {
	width: 64px;
	height: 64px;
	display: grid;
	place-items: center;
	background: var(--bg-10);
	border-radius: 50%;
	transition: background 0.3s;
}

.title {
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	margin: 8px 0 0 0;
	color: var(--black-80);
}

.emptyApps {
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
	width: 150px;
	height: 150px;
	font-size: 16px;
	font-weight: bold;
}
