.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    color: black;
}

.noDataContainer {
	text-align: center;
	padding: 40px 12px 30px 12px;
}

.noDataText {
	font-size: 18px;
	line-height: 34px;
	letter-spacing: 0.75px;
	text-align: center;
	margin-bottom: 0;
	color: #000000;
}

.noFileIconContainer {
	width: 64px;
	height: 64px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
	background: #f0f2f5;
	border-radius: 50%;
}
