.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	max-width: 72px;
	height: 100%;
	padding-bottom: 150px;
	background: #ffffff;
	box-shadow: 1px 0px 32px rgba(0, 0, 0, 0.12);
	transition: max-width 0.3s ease;
	z-index: 1000;
}

/* Hide scrollbar but allow scrolling */
.sidebar {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

.sidebarExpanded {
	max-width: 275px;
}

.toggleButton {
	position: sticky;
	top: 0;
	width: 100%;
	height: 65px;
	padding: 0px 24px;
	background: #fff;
	border: none;
	border-bottom: 1px solid var(--bg-50);
	outline: none;
	cursor: pointer;
}

.logoContainer {
	display: flex;
	align-items: center;
	gap: 32px;
}

.homeLink {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.15s, visibility 0.15s;
}

.homeLinkVisible {
	visibility: visible;
	opacity: 1;
}

.logo {
	width: 126px;
}

.links {
	display: grid;
	gap: 6px;
	padding: 8px 8px;
}
