.infoContainer {
	color: black;
	display: flex;
	flex-direction: column;
}

.companyName {
	color: rgb(77, 77, 77);
	font-size: 12px;
	text-decoration: none;
}

.name {
	color: black;
	font-weight: bold;
}

.avatarContainer {
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
}

.avatar {
	width: 38px;
	height: 38px;
	display: grid;
	place-items: center;
	background: #ccc;
	border-radius: 50%;
}

.downIcon {
	width: 24px;
}
