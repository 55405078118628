.dash {
	height: 2px;
	width: 14px;
	background-color: #278ea5;
    margin: 0 8px;
}

.label {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.25px;
	color: #6e7c91;
	width: 103px;
}

.section {
	display: flex;
	flex-direction: column;
    width: 100%;
}

.radioColumn {
	display: flex;
	flex-direction: column;
}

.customerNameContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.footer {
	display: flex;
	justify-content: flex-end;
}
