.contentHeader {
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
}

.contentHeaderContainer {
	display: flex;
	margin-left: auto;
	align-items: center;
	gap: 12px;
}

.filterOptions {
	display: flex;
	flex-direction: column;
}

.filterOption {
	margin-bottom: 10px;
}

.layoutContent {
	background: white;
}

.tabContainer {
	display: flex;
	gap: 40px;
}

.link {
	color: #278ea5;
}

.unassigned {
	color: rgba(0, 0, 0, 0.25);
}

.attachments {
	display: flex;
	max-width: 85px;
}

.attachment {
	position: relative;
	width: 16px;
	height: 16px;
	cursor: pointer;
	flex-wrap: wrap;
	display: flex;
	align-items: center;
}

.attachmentLink {
	position: absolute;
	width: 100%;
	height: 100%;
}

.staleIndicator {
	width: 12px;
	height: 12px;
	background: #278ea5;
	margin-right: 12px;
	border-radius: 50%;
	flex-shrink: 0;
}

.weekHeading {
	color: black;
	padding: 6px 12px;
	border: 1px solid #d9e0e9;
	border-radius: 0px;
	font-weight: bold;
	font-size: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	white-space: nowrap;
	margin-bottom: 12px;
}

.status {
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
	font-weight: bold;
}

.locations {
	font-size: 13px;
}

.location {
	display: inline-block;
	font-size: 12px;
}

.locationIndicator {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #1890ff;
	display: inline-block;
	margin-right: 6px;
}

.badge {
	color: black;
	background: #eeeeee;
	align-self: flex-start;
	margin-bottom: 6px;
	padding: 2px 6px;
	font-size: 11px;
	font-weight: bold;
	border-radius: 0px;
}

.salesOrderIdColumn {
	display: flex;
	align-items: center;
	gap: 2px;
}

.productListModal {
	padding: 16px;
}

.modalTitleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}

.modalTitle,
.printTitle {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 0;
	color: #242529;
}

.printTitle {
	font-size: 22px;
	text-align: center;
	margin-bottom: 30px;
	display: none;
}

.closeIcon {
	width: 25px;
	height: 25px;
	stroke: #000;
	transition: stroke 0.2s;
	cursor: pointer;
}

.closeIcon:hover {
	stroke: #aaa;
}

.modalContent {
	position: relative;
	max-height: calc(100vh - 300px);
	overflow-y: auto;
}

.invoiceNo {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 16px;
	color: #242529;
}

.summaryRow {
	font-weight: 600;
	position: sticky;
	bottom: 0;
	background: #fff;
	z-index: 1;
}

.summaryRow :nth-child(3) {
	text-align: right;
}

.productListTables > :last-child {
	display: none;
}

.invoicesTablesTotalColumn {
	display: flex;
	justify-content: flex-end;
}

@media print {
	.productListTableContainer {
		padding: 60px;
	}

	.modalContent {
		max-height: none;
	}

	.productListTables > :first-child {
		display: none;
	}

	.productListTables > :last-child {
		display: initial;
	}

	.printTitle {
		display: block;
	}

	.invoicePopover {
		display: none !important;
	}
}
