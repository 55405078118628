.pickingModal {
	padding: 16px;
}

.modalTitleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}

.modalTitle,
.printTitle {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 0;
	color: #242529;
}

.printTitle {
	font-size: 22px;
	text-align: center;
	margin-bottom: 30px;
	display: none;
}

.closeIcon {
	width: 25px;
	height: 25px;
	stroke: #000;
	transition: stroke 0.2s;
	cursor: pointer;
}

.closeIcon:hover {
	stroke: #aaa;
}

.modalContent {
	position: relative;
}

.summaryRow {
	font-weight: 600;
	position: sticky;
	bottom: 0;
	background: #fff;
	z-index: 1;
}

.summaryRow :nth-child(3) {
	text-align: right;
}

.pickingTables > :last-child {
	display: none;
}

.title {
    font-size: 12px;
    font-weight: 500;
    color: #7d7d7d;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.filterOptions {
	display: grid;
	gap: 12px;
}

@media print {
	.pickingTableContainer {
		padding: 60px;
	}

	.modalContent {
		max-height: none;
	}

	.pickingTables > :first-child {
		display: none;
	}

	.pickingTables > :last-child {
		display: initial;
	}

	.printTitle {
		display: block;
	}

    .pickingProductInfoIcon {
		display: none !important;
	}
}