.overlay {
	position: relative;
	padding: 24px 24px 0 24px;
	max-width: 480px;
	max-height: calc(100vh - 150px);
	display: grid;
	grid-auto-rows: max-content;
	gap: 24px;
	background: #fff;
	box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.12);
	overflow-y: auto;
}

.overlay::-webkit-scrollbar {
	width: 8px;
	background: transparent;
}

.overlay::-webkit-scrollbar-thumb {
	background: #cacaca;
	border-radius: 21px;
}
